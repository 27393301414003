import { BASE_AUTH_URL } from '@config/index';
import { formatUrl } from 'url-lib';

import { sdkAuthRequest, sdkRequest } from '../sdk';
import { RentennialsUserUpdateData, RentennialsUserVerifiedData, UserDataSchema } from '../types/userDataSchema';

export interface UserRatingQuery {
  offset: number;
  limit: number;
  customer: boolean;
  seller: boolean;
}

const assembleUserRatingQuery = (
  userRatingQuery: Partial<UserRatingQuery>
): Omit<UserRatingQuery, 'seller' | 'customer'> & { customer?: boolean; seller?: boolean } => {
  return {
    offset: userRatingQuery.offset || 0,
    limit: userRatingQuery.limit || 10,
    ...(userRatingQuery.customer ? { customer: true } : { customer: false }),
    ...(userRatingQuery.seller ? { seller: true } : { seller: false })
  };
};

export const getUserRatingData = async (userId: string, userRatingQuery: Partial<UserRatingQuery>) => {
  const response = await sdkRequest(
    formatUrl(`users/${userId}/rating`, assembleUserRatingQuery(userRatingQuery)),
    undefined
  );
  return response;
};

export const getUserData = async (token?: string) => {
  const response = await sdkAuthRequest('users/me', undefined, token, BASE_AUTH_URL);
  return UserDataSchema.parse(response);
};

export const requestUpdateUserData = async (token?: string) => {
  const response = await sdkAuthRequest(
    'users/me/request-changes',
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      }
    },
    token,
    BASE_AUTH_URL
  );

  return response;
};

export const updateUserData = async (
  rentennialsUser: RentennialsUserUpdateData | RentennialsUserVerifiedData,
  userId: string,
  token?: string
) => {
  await sdkAuthRequest(
    `users/${userId}`,
    {
      method: 'PUT',
      body: JSON.stringify(rentennialsUser),
      headers: {
        'Content-Type': 'application/json'
      }
    },
    token,
    BASE_AUTH_URL
  );
};

export const deleteUserProfile = async () => {
  await sdkAuthRequest(`users/me/remove`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    }
  });
};
