import { checkDefinedValues } from '@utils/checkDefinedValues';
import { deepKeysToSnake } from '@utils/convertCase';
import { omitProps } from '@utils/omitProps';

import { FullUser, IncompleteUser, User } from '../types/userData';
import { RentennialsUserData, RentennialsUserUpdateData } from '../types/userDataSchema';

export const transformUser = (rentennialUser: RentennialsUserData) => {
  const user: User = {
    canUpdate: rentennialUser.can_udpate,
    hasRequestedChanges: rentennialUser.has_request_changes,
    userData: {
      email: rentennialUser.email,
      firstName: rentennialUser.firstName,
      lastName: rentennialUser.lastName,
      id: rentennialUser._id,
      number: rentennialUser.number,
      provider: rentennialUser.provider,
      fullName: rentennialUser.username,
      birthDate: rentennialUser.birth_date,
      pictureUrl: rentennialUser.picture_url ? rentennialUser.picture_url : '',
      phone: rentennialUser.phone
    },
    userContactPoint: {
      city:
        !rentennialUser.contactPoint?.city || rentennialUser.contactPoint.city === 'no-city-set'
          ? ''
          : rentennialUser.contactPoint.city,
      province:
        !rentennialUser.contactPoint?.province || rentennialUser.contactPoint.province === 'no-province-set'
          ? ''
          : rentennialUser.contactPoint.province,
      street:
        !rentennialUser.contactPoint?.street || rentennialUser.contactPoint.street === 'no-set'
          ? ''
          : rentennialUser.contactPoint.street,
      streetNumber:
        !rentennialUser.contactPoint?.street_number || rentennialUser.contactPoint.street_number === 'no-set'
          ? ''
          : rentennialUser.contactPoint.street_number,
      id: rentennialUser.contactPoint?._id || ''
    },
    userAddress: {
      country: rentennialUser.country
    },
    userVerification: {
      isVerified: rentennialUser.is_verified,
      verfiedDate: rentennialUser.verified_date,
      identityVerificationStatus: rentennialUser.stripe.identity_verification_status
    },
    userPublication: {
      isPublishEnabled: rentennialUser.published_enabled,
      publishDate: rentennialUser.published_date
    },
    completionType: 'incomplete'
  };

  const testUser = omitProps(user, 'userContactPoint');
  if (checkDefinedValues(testUser)) {
    return {
      ...user,
      completionType: 'full'
    } as FullUser;
  }

  return user;
};

export const transformToRentennialsUser = (user: IncompleteUser) => {
  const { userAddress, userData, userContactPoint, userPublication, userVerification } = user;

  const rentennialsUser: RentennialsUserUpdateData = {
    email: userData.email,
    firstName: userData.firstName,
    lastName: userData.lastName,
    number: userData.number,
    provider: userData.provider,
    username: userData.fullName,
    birth_date: userData.birthDate,
    picture_url: userData.pictureUrl,
    contactPoint: deepKeysToSnake(userContactPoint),
    city: userAddress.city,
    country: userAddress.country,
    postal_code: userAddress.postalCode,
    province: userAddress.province,
    street: userAddress.street,
    phone: userAddress.phone,
    is_verified: userVerification.isVerified,
    verified_date: userVerification.verfiedDate,
    stripe: {
      identity_verification_status: userVerification.identityVerificationStatus
    },
    published_enabled: userPublication.isPublishEnabled,
    published_date: userPublication.publishDate
  };

  return rentennialsUser;
};
