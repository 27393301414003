import { Features, isFeatureEnabled } from '@utils/featureFlag';

export enum AnalyticsEventsName {
  SEARCH_TYPE = 'web_search',
  PAGES = 'web_pages',
  SHARE = 'web_share',
  PRICE = 'web_price',
  RESERVE = 'web_reserve',
  BOOKING = 'web_booking',
  PAYMENT = 'web_payment',
  QUESTION = 'web_question',
  APP_DOWNLOAD = 'web_store_pick',
  PROFILE = 'web_profile',
  TRIP = 'web_trip',
  LANGUAGE = 'web_language',
  STORIES = 'web_stories'
}

export enum AnalyticsPages {
  WEB_HOME = AnalyticsEventsName.PAGES + '_home',
  WEB_LISTING = AnalyticsEventsName.PAGES + '_listing',
  WEB_DETAILS = AnalyticsEventsName.PAGES + '_details',
  WEB_CAMPAIGN = AnalyticsEventsName.PAGES + '_campaign',
  WEB_PROFILE = AnalyticsEventsName.PAGES + '_profile',
  WEB_TRIP = AnalyticsEventsName.PAGES + '_trip',
  WEB_STORIES = AnalyticsEventsName.PAGES + '_stories',
  WEB_VIEW_HOME = 'view_home'
}

export enum AnalyticsSearchType {
  WEB_CITY_PICKER = AnalyticsEventsName.SEARCH_TYPE + '_city_picker',
  WEB_SEARCH_BUTTON = AnalyticsEventsName.SEARCH_TYPE + '_search_button',
  WEB_MENU_LINK = AnalyticsEventsName.SEARCH_TYPE + '_menu_link',
  WEB_VEHICLE_SELECTION = AnalyticsEventsName.SEARCH_TYPE + '_vehicle_selection',
  WEB_FOOTER_LINK = AnalyticsEventsName.SEARCH_TYPE + '_footer_link',
  WEB_NEWS_LINK = AnalyticsEventsName.SEARCH_TYPE + '_news',
  WEB_SEARCH_VEHICLE = 'search_vehicle',
  WEB_VIEW_VEHICLE = 'view_vehicle'
}

export enum AnalyticsShare {
  WEB_SHARE_WHATSAPP = AnalyticsEventsName.SHARE + '_whatsapp',
  WEB_SHARE_LINKEDIN = AnalyticsEventsName.SHARE + '_linkedin',
  WEB_SHARE_FACEBOOK = AnalyticsEventsName.SHARE + '_facebook',
  WEB_SHARE_LINK = AnalyticsEventsName.SHARE + '_link',
  WEB_WHATSAPP_BUTTON = AnalyticsEventsName.SHARE + '_whatsapp_button'
}

export enum AnalyticsPriceCheck {
  WEB_DATE_RANGE_CHECK = AnalyticsEventsName.PRICE + '_details_date_range_check',
  WEB_BREAKDOWN_CHECK = AnalyticsEventsName.PRICE + '_details_breakdown_check'
}

export enum AnalyticsReserve {
  WEB_RESERVE_REQUEST_NEED_VERIFICATION = AnalyticsEventsName.RESERVE + '_under_review',
  WEB_RESERVE_LOGIN = AnalyticsEventsName.RESERVE + '_login',
  WEB_RESERVE = AnalyticsEventsName.RESERVE + '_perfom',
  WEB_RESERVE_REQUEST = AnalyticsEventsName.RESERVE + '_request',
  WEB_RESERVE_SEND_REQUEST = 'send_request',
  WEB_RESERVER_REQUEST_PURCHASE_INTENT = 'request_purchase_intent'
}
export enum AnalyticsBookingPaid {
  WEB_BOOKING_PAID_REQUEST = AnalyticsEventsName.BOOKING + '_paid_request',
  WEB_BOOKING_AUTO_APPROVAL_PAID_REQUEST = AnalyticsEventsName.BOOKING + '_auto_approval_paid_request',
  WEB_BOOKING_CHECKING_REQUEST = AnalyticsEventsName.BOOKING + '_checking_request'
}

export enum AnalyticsPayment {
  WEB_PAYMENT_MELI = AnalyticsEventsName.PAYMENT + '_paid_meli',
  WEB_PAYMENT_STRIPE = AnalyticsEventsName.PAYMENT + '_paid_stripe',
  WEB_PAYMENT_SUCCESS = AnalyticsEventsName.PAYMENT + '_success',
  WEB_PAYMENT_PURCHASE = 'purchase',
  WEB_PAYMENT_PURCHASE_INTENT = 'purchase_intent'
}

export enum AnalyiticsQuestion {
  WEB_QUESTION = AnalyticsEventsName.QUESTION + '_question',
  PENDING_WEB_QUESTION = AnalyticsEventsName.QUESTION + '_pending'
}

export enum AnalyiticsProfile {
  WEB_PROFILE = AnalyticsEventsName.PROFILE + '_update'
}

export enum AnalyiticsAppDownload {
  PLAY_STORE = AnalyticsEventsName.APP_DOWNLOAD + '_play_store',
  APP_STORE = AnalyticsEventsName.APP_DOWNLOAD + '_app_store'
}

export enum AnalyiticsLanguageChange {
  LANGUAGE_CHANGE = AnalyticsEventsName.LANGUAGE + '_change'
}

export enum AnalyticsStories {
  CLICK_STORIE_FROM_LISTING = AnalyticsEventsName.STORIES + '_click_storie_from_listing',
  CLICK_MORE_INFO_FROM_STORIES = AnalyticsEventsName.STORIES + '_click_more_info_from_stories'
}

declare global {
  interface Window {
    dataLayer?: unknown[];
    gtag?: (command: 'event', eventName: string, data: Record<string, unknown>) => void;
  }
}

const gtag = (command: 'event', eventName: string, data: Record<string, unknown>): void => {
  if (window.gtag) {
    window.gtag(command, eventName, data);
  } else if (window.dataLayer) {
    if (Array.isArray(window.dataLayer)) {
      window.dataLayer.push([command, eventName, data]);
    } else {
      console.warn('[Analytics] `dataLayer` variable must be an array');
    }
  } else {
    console.warn('[Analytics] No `gtag` function or `dataLayer` variable found on the page');
  }
};

export class Analytics {
  static event(eventName: string, data: Record<string, unknown>) {
    // Try-Catch is just to prevent Analytics code from crashing the app
    try {
      if (isFeatureEnabled(Features.analytics)) {
        gtag('event', eventName, data);
      }
    } catch (err) {
      console.error('[Analyitics] An error ocurred while tracking an event', err);
    }
  }
}
