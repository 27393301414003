import { Suspense } from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import ErrorBoundary from '@components/errors/ErrorBoundary';
import Loading from '@components/layout/Loading';
import RentennialsHead from '@components/layout/RentennialsHead';
import { AuthProvider } from '@context/AuthContex';
import { GlobalProvider } from '@context/GlobalContext';
import { NotificationsProvider } from '@context/NotificationsContext';
import { Features, isFeatureEnabled } from '@utils/featureFlag';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { SessionProvider } from 'next-auth/react';
import { appWithTranslation } from 'next-i18next';
import { NextIntlClientProvider } from 'next-intl';

import theme from '../theme';

import { CLARITY_PROJECT_ID } from './../constants/index';

import 'react-spring-bottom-sheet/dist/style.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '../styles/globals.css';

const RentennialsApp = ({ Component, pageProps: { session, ...pageProps } }: AppProps) => {
  const router = useRouter();
  return (
    <>
      {isFeatureEnabled(Features.analytics) && (
        <>
          <Script src="https://www.googletagmanager.com/gtag/js?id=G-9E4GMXX0FQ" strategy="afterInteractive" />
          <Script id="google-analytics" strategy="afterInteractive">
            {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-9E4GMXX0FQ');
        `}
          </Script>
          <Script id="google-tag-manager" strategy="afterInteractive">
            {`
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-NRMDS3P');
        `}
          </Script>
        </>
      )}
      <Script id="clarity-script" strategy="afterInteractive">
        {`
          (function(c,l,a,r,i,t,y){
          c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
          t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
          y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
          })(window, document, "clarity", "script", "${CLARITY_PROJECT_ID}");
          `}
      </Script>
      {/* <Script id="tracker-script">
        {`
        function loadScript(a){
        var b=document.getElementsByTagName("head")[0],c=document.createElement("script");c.type="text/javascript",c.src="https://tracker.metricool.com/resources/be.js",c.onreadystatechange=a,c.onload=a,b.appendChild(c)%7DloadScript(function()%7BbeTracker.t(%7Bhash:"5b28dce1f8d520f684a769306bf124a1"%7D)%7D);
        `}
      </Script> */}
      <ChakraProvider theme={theme}>
        <Suspense fallback={<Loading />}>
          <NextIntlClientProvider
            locale={router.locale}
            messages={pageProps.messages}
            timeZone="Argentina/Buenos_Aires"
          >
            <SessionProvider session={session}>
              <AuthProvider {...pageProps}>
                <GlobalProvider {...pageProps}>
                  <NotificationsProvider>
                    <RentennialsHead />
                    <ErrorBoundary>
                      <Component {...pageProps} />
                    </ErrorBoundary>
                  </NotificationsProvider>
                </GlobalProvider>
              </AuthProvider>
            </SessionProvider>
          </NextIntlClientProvider>
        </Suspense>
      </ChakraProvider>
    </>
  );
};

export default appWithTranslation(RentennialsApp);
